import React from "react"
import Header from "./header"
import Img from "gatsby-image"
import scrollTo from "gatsby-plugin-smoothscroll"

const Hero = ({ title, heading, subheading, image }) => {
  return (
    <div
      className="relative h-screen"
      style={{ height: "calc(var(--vh, 1vh) * 100)" }}
    >
      {/* {image.childImageSharp ? (
        <Img
          fluid={image.childImageSharp.fluid}
          className="h-full w-full top-0 left-0 bg-gray-900 z-0 "
        />
      ) : (
        <img
          src={image}
          alt=""
          style={{ objectFit: "cover" }}
          className="h-full w-full top-0 left-0 bg-gray-900 z-0 "
        />
      )} */}
      <video
        className="video-player"
        id="BgVideo"
        height="100%"
        width="100%"
        autoPlay
        loop
        muted
        playsInline
        style={{ objectFit: "cover" }}
      >
        <source
          src="https://paradise-machine.s3-us-west-2.amazonaws.com/output.mp4"
          type="video/mp4"
        />
        <source
          src="https://paradise-machine.s3-us-west-2.amazonaws.com/output.webm"
          type="video/ogg"
        />
      </video>

      <div
        style={{ backgroundColor: "#717171" }}
        className="overlay h-full w-full absolute opacity-50 top-0 left-0 z-0 h-full w-full"
      ></div>
      <div className="absolute top-0 left-0 bottom-0 right-0 h-full w-full md:px-10 lg:px-20 p-5 md:py-10 text-white ">
        <Header title={title} />
        <div
          className="z-20 flex flex-col justify-center items-center h-full pb-40"
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease"
        >
          <h1 className="text-4xl lg:text-5xl font-bold uppercase leading-tight">
            {heading}
          </h1>
        </div>
        <div className="z-20 absolute bottom-0 left-0 w-full text-center mb-8">
          <button
            aria-label="button"
            onClick={() => scrollTo(`#CallToAction`)}
            className="cursor-pointer inline-block p-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-10 h-10 inline-block"
              viewBox="0 0 50 50"
            >
              <path
                fill="none"
                stroke="#FFFEFE"
                strokeLinecap="round"
                strokeWidth="4"
                d="M45 22L25 42 5 22"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Hero
