import React from "react";
import propTypes from "prop-types";
import pm_icon from "../../static/img/pm_icon.png";

const Footer = ({ data }) => (
  <footer
    id="Contact"
    className="flex flex-col-reverse md:flex-row justify-between bg-customBlack text-white p-5 md:py-16 md:px-12"
  >
    <div className="container flex flex-wrap justify-center mx-auto">
      <div className="w-full md:w-4/12 mb-10">
        <img src={pm_icon} width="45px" className="mb-3" />
        <h1 className="mb-3">Paradise Machine Corporation</h1>
        <p className="whitespace-pre-line leading-normal">
          <a href="https://goo.gl/maps/4fx4NExSAcyXhbS7A">{data.address}</a>
        </p>
      </div>

      <div className="w-full md:w-4/12">
        <p className="flex justify-items-center mb-4">
          <svg
            width="25"
            height="25"
            className="mr-4"
            viewBox="0 0 58 58"
            fill="#efefef"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="Page-1" fill="#efefef" fillRule="evenodd">
              <g id="003---Call" fillRule="nonzero" transform="translate(-1)">
                <path
                  id="Shape"
                  d="m25.017 33.983c-5.536-5.536-6.786-11.072-7.068-13.29-.0787994-.6132828.1322481-1.2283144.571-1.664l4.48-4.478c.6590136-.6586066.7759629-1.685024.282-2.475l-7.133-11.076c-.5464837-.87475134-1.6685624-1.19045777-2.591-.729l-11.451 5.393c-.74594117.367308-1.18469338 1.15985405-1.1 1.987.6 5.7 3.085 19.712 16.855 33.483s27.78 16.255 33.483 16.855c.827146.0846934 1.619692-.3540588 1.987-1.1l5.393-11.451c.4597307-.9204474.146114-2.0395184-.725-2.587l-11.076-7.131c-.7895259-.4944789-1.8158967-.3783642-2.475.28l-4.478 4.48c-.4356856.4387519-1.0507172.6497994-1.664.571-2.218-.282-7.754-1.532-13.29-7.068z"
                />
                <path
                  id="Shape"
                  d="m47 31c-1.1045695 0-2-.8954305-2-2-.0093685-8.2803876-6.7196124-14.9906315-15-15-1.1045695 0-2-.8954305-2-2s.8954305-2 2-2c10.4886126.0115735 18.9884265 8.5113874 19 19 0 1.1045695-.8954305 2-2 2z"
                />
                <path
                  id="Shape"
                  d="m57 31c-1.1045695 0-2-.8954305-2-2-.0154309-13.800722-11.199278-24.9845691-25-25-1.1045695 0-2-.8954305-2-2s.8954305-2 2-2c16.008947.01763587 28.9823641 12.991053 29 29 0 .530433-.2107137 1.0391408-.5857864 1.4142136-.3750728.3750727-.8837806.5857864-1.4142136.5857864z"
                />
              </g>
            </g>
          </svg>
          <a href="tel:+16049056964">{data.phone}</a>
        </p>
        <p className="flex justify-items-center mb-4">
          <svg
            className="mr-4"
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 40 39.991"
          >
            <path
              d="M20,11.98A10.253,10.253,0,1,0,30.252,22.233,10.237,10.237,0,0,0,20,11.98ZM20,28.9a6.666,6.666,0,1,1,6.666-6.666A6.678,6.678,0,0,1,20,28.9ZM33.063,11.561a2.392,2.392,0,1,1-2.392-2.392A2.386,2.386,0,0,1,33.063,11.561Zm6.791,2.427c-.152-3.2-.883-6.041-3.23-8.379s-5.176-3.07-8.379-3.23c-3.3-.187-13.2-.187-16.5,0C8.55,2.53,5.713,3.262,3.366,5.6S.3,10.775.135,13.979c-.187,3.3-.187,13.2,0,16.5.152,3.2.883,6.041,3.23,8.379s5.176,3.07,8.379,3.23c3.3.187,13.2.187,16.5,0,3.2-.152,6.041-.883,8.379-3.23s3.07-5.176,3.23-8.379c.187-3.3.187-13.189,0-16.491ZM35.589,34.021a6.749,6.749,0,0,1-3.8,3.8c-2.632,1.044-8.879.8-11.788.8s-9.165.232-11.788-.8a6.749,6.749,0,0,1-3.8-3.8c-1.044-2.632-.8-8.879-.8-11.788s-.232-9.165.8-11.788a6.749,6.749,0,0,1,3.8-3.8c2.632-1.044,8.879-.8,11.788-.8s9.165-.232,11.788.8a6.749,6.749,0,0,1,3.8,3.8c1.044,2.632.8,8.879.8,11.788S36.633,31.4,35.589,34.021Z"
              transform="translate(0.005 -2.238)"
              fill="#efefef"
            />
          </svg>{" "}
          <a
            href="https://www.instagram.com/paradise_machine/"
            target="_blank"
            rel="noreferrer"
          >
            @paradise_machine
          </a>
        </p>
        <p className="flex justify-items-center mb-4">
          <svg
            className="mr-4"
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="#efefef"
            viewBox="0 0 512 512"
          >
            <g>
              <g>
                <path
                  d="M458.667,85.333H53.333C23.936,85.333,0,109.269,0,138.667v234.667c0,29.397,23.936,53.333,53.333,53.333h405.333
                        c29.397,0,53.333-23.936,53.333-53.333V138.667C512,109.269,488.064,85.333,458.667,85.333z M490.667,373.333
                        c0,17.643-14.357,32-32,32H53.333c-17.643,0-32-14.357-32-32V138.667c0-17.643,14.357-32,32-32h405.333c17.643,0,32,14.357,32,32
                        V373.333z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  d="M467.456,132.651c-3.307-4.864-9.941-6.08-14.827-2.773L277.675,249.579c-13.184,9.003-30.208,9.003-43.371,0
                        L59.349,129.877c-4.885-3.349-11.52-2.091-14.827,2.773c-3.307,4.864-2.069,11.499,2.795,14.827l174.955,119.701
                        c10.24,7.019,21.995,10.517,33.728,10.517s23.488-3.499,33.728-10.517l174.955-119.701
                        C469.547,144.149,470.784,137.515,467.456,132.651z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  d="M189.525,259.819c-3.776-4.501-10.517-5.12-15.04-1.365l-128,106.667c-4.523,3.776-5.141,10.517-1.365,15.04
                        c2.112,2.539,5.141,3.84,8.213,3.84c2.411,0,4.843-0.811,6.827-2.475l128-106.667
                        C192.683,271.083,193.301,264.341,189.525,259.819z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  d="M465.515,365.12l-128-106.667c-4.544-3.755-11.264-3.136-15.04,1.365c-3.776,4.544-3.157,11.264,1.365,15.04l128,106.667
			                  c2.005,1.664,4.416,2.475,6.827,2.475c3.051,0,6.08-1.301,8.213-3.84C470.656,375.616,470.037,368.896,465.515,365.12z"
                />
              </g>
            </g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
          </svg>
          <a href={"mailto:" + data.contact} className="whitespace-no-wrap">{data.contact}</a>
        </p>
      </div>
    </div>
  </footer>
);
Footer.propTypes = {
  data: propTypes.object,
};
export default Footer;
