import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
const Work = ({ data }) => {
  return (
    <>
      <div className="w-full">
        {data.headerImage.childImageSharp ? (
          <Img
            fluid={{
              ...data.headerImage.childImageSharp.fluid,
              aspectRatio: 2 / 1,
            }}
          />
        ) : (
          <img src={data.headerImage} alt="" />
        )}
      </div>
      <div
        id="Work"
        className="py-16 px-12 text-center bg-customBlue text-white"
      >
        <h1
          className="text-4xl font-bold"
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease"
        >
          OUR WORK
        </h1>
        <div
          className="flex flex-wrap justify-around items-center partners"
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease"
        >
          {data.logos.map((logo) =>
            logo.image && logo.image.childImageSharp ? (
              <Img
                style={{ width: "170px" }}
                key={logo.image.id}
                className="mb-4 md:ml-4"
                fluid={logo.image.childImageSharp.fluid}
              />
            ) : (
              <img
                style={{ width: "170px" }}
                key={logo.image}
                className="mb-4 md:ml-4"
                src={logo.image}
                alt=""
              />
            )
          )}
        </div>
      </div>
      <div className="flex flex-wrap">
        <div className="w-full md:w-1/3">
          {data.work1.childImageSharp ? (
            <Img
              fluid={{
                ...data.work1.childImageSharp.fluid,
                aspectRatio: 1,
              }}
            />
          ) : (
            <img src={data.work1} alt="" />
          )}
        </div>
        <div className="w-full md:w-1/3">
          {data.work2.childImageSharp ? (
            <Img
              fluid={{
                ...data.work2.childImageSharp.fluid,
                aspectRatio: 1,
              }}
            />
          ) : (
            <img src={data.work2} alt="" />
          )}
        </div>
        <div className="w-full md:w-1/3">
          {data.work3.childImageSharp ? (
            <Img
              fluid={{
                ...data.work3.childImageSharp.fluid,
                aspectRatio: 1,
              }}
            />
          ) : (
            <img src={data.work3} alt="" />
          )}
        </div>
        <div className="w-full md:w-1/3 hidden md:block">
          {data.work4.childImageSharp ? (
            <Img
              fluid={{
                ...data.work4.childImageSharp.fluid,
                aspectRatio: 1,
              }}
            />
          ) : (
            <img src={data.work4} alt="" />
          )}
        </div>
        <div className="w-full md:w-1/3 hidden md:block">
          {data.work5.childImageSharp ? (
            <Img
              fluid={{
                ...data.work5.childImageSharp.fluid,
                aspectRatio: 1,
              }}
            />
          ) : (
            <img src={data.work5} alt="" />
          )}
        </div>
        <div className="w-full md:w-1/3 hidden md:block">
          {data.work6.childImageSharp ? (
            <Img
              fluid={{
                ...data.work6.childImageSharp.fluid,
                aspectRatio: 1,
              }}
            />
          ) : (
            <img src={data.work6} alt="" />
          )}
        </div>
      </div>
    </>
  );
};
Work.propTypes = {
  data: PropTypes.object,
};
export default Work;
