import React, { useState } from "react";
import scrollTo from "gatsby-plugin-smoothscroll";
import propTypes from "prop-types";
const Header = ({ title }) => {
  const [isExpanded, toggleExpansion] = useState(false);
  const links = ["Services", "Work", "Contact"];
  return (
    <header className="flex justify-between items-center flex-wrap ">
      <h1
        className="font-bold text-3xl uppercase tracking-tight italic"
        data-sal="slide-up"
        data-sal-delay="300"
        data-sal-easing="ease"
      >
        {" "}
        {title}{" "}
      </h1>
      <button
        aria-label="button"
        className="flex cursor-pointer items-center block px-3 py-2 text-white rounded-lg md:hidden"
        onClick={() => toggleExpansion(!isExpanded)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          viewBox="0 0 50 50"
        >
          <path
            fill="none"
            stroke="#FFFEFE"
            strokeWidth="5"
            d="M50 25L0 25M50 10L0 10M0 40L50 40"
          />
        </svg>{" "}
      </button>{" "}
      <nav
        className={`${
          isExpanded ? `block  mt-20 open` : `hidden`
        } md:block md:relative flex flex-col md:flex-row uppercase md:capitalize md:px-0 bg-customBlue md:bg-transparent text-center absolute left-0 top-0 px-5 md:px-0 md:mt-0 md:flex md:items-center w-full md:w-auto text-center kel`}
      >
        {" "}
        {links.map((link) => (
          <a
            aria-label={link}
            key={link}
            onClick={() => {
              toggleExpansion(!isExpanded);
              scrollTo(`#${link}`);
              return;
            }}
            className="w-full uppercase text-3xl md:text-lg cursor-pointer py-4 md:py-2 md:inline-block  md:mt-0 md:ml-4 mb-2 px-5"
          >
            {link}{" "}
          </a>
        ))}{" "}
        <span className="w-full text-customOrange uppercase text-3xl md:text-lg cursor-pointer py-4 md:py-2 sm:inline-block md:justify-center md:mt-0 md:ml-4 mb-3 px-5 md:flex justify-items-center">
          <svg
            width="25"
            height="25"
            className="mr-4 header-icon inline-block"
            viewBox="0 0 58 58"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="Page-1" fillRule="evenodd">
              <g id="003---Call" fillRule="nonzero" transform="translate(-1)">
                <path
                  id="Shape"
                  d="m25.017 33.983c-5.536-5.536-6.786-11.072-7.068-13.29-.0787994-.6132828.1322481-1.2283144.571-1.664l4.48-4.478c.6590136-.6586066.7759629-1.685024.282-2.475l-7.133-11.076c-.5464837-.87475134-1.6685624-1.19045777-2.591-.729l-11.451 5.393c-.74594117.367308-1.18469338 1.15985405-1.1 1.987.6 5.7 3.085 19.712 16.855 33.483s27.78 16.255 33.483 16.855c.827146.0846934 1.619692-.3540588 1.987-1.1l5.393-11.451c.4597307-.9204474.146114-2.0395184-.725-2.587l-11.076-7.131c-.7895259-.4944789-1.8158967-.3783642-2.475.28l-4.478 4.48c-.4356856.4387519-1.0507172.6497994-1.664.571-2.218-.282-7.754-1.532-13.29-7.068z"
                ></path>
                <path
                  id="Shape"
                  d="m47 31c-1.1045695 0-2-.8954305-2-2-.0093685-8.2803876-6.7196124-14.9906315-15-15-1.1045695 0-2-.8954305-2-2s.8954305-2 2-2c10.4886126.0115735 18.9884265 8.5113874 19 19 0 1.1045695-.8954305 2-2 2z"
                ></path>
                <path
                  id="Shape"
                  d="m57 31c-1.1045695 0-2-.8954305-2-2-.0154309-13.800722-11.199278-24.9845691-25-25-1.1045695 0-2-.8954305-2-2s.8954305-2 2-2c16.008947.01763587 28.9823641 12.991053 29 29 0 .530433-.2107137 1.0391408-.5857864 1.4142136-.3750728.3750727-.8837806.5857864-1.4142136.5857864z"
                ></path>
              </g>
            </g>
          </svg>
          <a href="tel:+16049056964" className="whitespace-no-wrap">
            (604) 905-6964
          </a>
        </span>
      </nav>{" "}
    </header>
  );
};
Header.propTypes = {
  title: propTypes.any,
};
export default Header;
