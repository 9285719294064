/* global google */
import React, { Component } from "react";
import map_marker from "../../static/img/map_marker.png";
// import PropTypes from "prop-types";

class Contact extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const myLatLng = { lat: 50.089167, lng: -123.035861 };
    const map = new google.maps.Map(document.getElementById("map"), {
      zoom: 4,
      center: myLatLng,
      styles: [
        {
          elementType: "geometry",
          stylers: [
            {
              color: "#f5f5f5",
            },
          ],
        },
        {
          elementType: "labels.icon",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#616161",
            },
          ],
        },
        {
          elementType: "labels.text.stroke",
          stylers: [
            {
              color: "#f5f5f5",
            },
          ],
        },
        {
          featureType: "administrative.land_parcel",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#bdbdbd",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "geometry",
          stylers: [
            {
              color: "#eeeeee",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#757575",
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "geometry",
          stylers: [
            {
              color: "#e5e5e5",
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#9e9e9e",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "geometry",
          stylers: [
            {
              color: "#ffffff",
            },
          ],
        },
        {
          featureType: "road.arterial",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#757575",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "geometry",
          stylers: [
            {
              color: "#dadada",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#616161",
            },
          ],
        },
        {
          featureType: "road.local",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#9e9e9e",
            },
          ],
        },
        {
          featureType: "transit.line",
          elementType: "geometry",
          stylers: [
            {
              color: "#e5e5e5",
            },
          ],
        },
        {
          featureType: "transit.station",
          elementType: "geometry",
          stylers: [
            {
              color: "#eeeeee",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [
            {
              color: "#c9c9c9",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#9e9e9e",
            },
          ],
        },
      ],
    });
    const contentString = `
        <h1>PARADISE MACHINE</h1>
        <p style="margin-top: 5px; line-height: 20px; margin-bottom: 5px;">
            #1-1220 Alpha Lake Rd.<br />
            Whistler, B.C. V8E0H7
        </p>
        <a style="color: #b59a48; font-size: 15px;" href="https://goo.gl/maps/4fx4NExSAcyXhbS7A" target="_blank">Get Directions</a>
    `;
    const infowindow = new google.maps.InfoWindow({
      content: contentString,
    });
    const marker = new google.maps.Marker({
      position: myLatLng,
      map,
      title: "Paradise Machine",
      icon: {
        url: map_marker,
        optimized: false,
      },
    });
    marker.addListener("click", () => {
      infowindow.open(map, marker);
    });
  }

  render() {
    return (
      <>
        <div id="contact-form" className="text-center mb-10 py-16">
          <h1
            className="font-bold text-4xl md:text-5xl uppercase leading-tight"
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease"
          >
            GET IN TOUCH TO LEARN MORE
          </h1>
        </div>
        <div className="container flex justify-center mb-16 mx-auto">
          <div className="w-9/12">
            <form name="contact" method="POST" data-netlify="true">
            <input type="hidden" name="form-name" value="contact" />
              <div className="mb-4">
                <label className="block mb-2">Your Name (required)</label>
                <input
                  className="bg-gray-100 border-b-2 outline-none border-gray-600 w-full h-16 p-3 text-gray-700"
                  type="text"
                  name="name"
                />
              </div>
              <div className="mb-4">
                <label className="block mb-2">Your Email (required)</label>
                <input
                  className="bg-gray-100 border-b-2 outline-none border-gray-600 w-full h-16 p-3 text-gray-700"
                  type="text"
                  name="email"
                />
              </div>
              <div className="mb-4">
                <label className="block mb-2">Subject</label>
                <input
                  className="bg-gray-100 border-b-2 outline-none border-gray-600 w-full h-16 p-3 text-gray-700"
                  type="text"
                  name="subject"
                />
              </div>
              <div className="mb-4">
                <label className="block mb-2">Your Message</label>
                <textarea
                  className="bg-gray-100 border-b-2 outline-none border-gray-600 w-full h-20 p-3 text-gray-700"
                  name="message"
                ></textarea>
              </div>
              <button
                type="submit"
                className="md:mt-8 bg-customOrange inline-block text-white text-xl font-bold tracking-wide px-24 py-4 transition duration-300 hover:bg-opacity-75 kel"
              >
                SEND
              </button>
            </form>
          </div>
        </div>
        <div id="map" style={{ height: "500px" }}></div>
      </>
    );
  }
}

export default Contact;
